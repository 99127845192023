<template>
  <v-dialog data-app v-model="opened" @input="onToggle()">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>
      <v-card-title>Создание региона</v-card-title>
      <v-card-text>
        <v-form ref="createCityForm" lazy-validation>
          <v-row class="row-d-block">
            <v-subheader>Название</v-subheader>
            <v-autocomplete
                :loading="loadingCities"
                v-model="region"
                :items="cities"
                hide-no-data
                item-text="name"
                return-object
                :search-input.sync="searchCities"
                :no-filter="true"
                outlined
                required
                :rules="[v=>!!v || 'Введите название города']"/>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn :loading="loading" @click="create">Создать</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AddRegion",
  data() {
    return {
      opened: false,
      city_id: null,
      region: {},
      loading: false,
      loadingCities: false,
      searchCities: null,
      cities: [],
    }
  },
  methods: {
    open() {
      this.opened = true
    },
    onToggle() {
      this.region = {}
      this.cities = []
    },
    findCity(city) {
      this.loadingCities = true;
      this.$store.state.server.request('cities/findCity', {q: city}, (data) => {
        this.cities = data.response;
        console.log('cities', data.response)
        this.loadingCities = false
      }, () => {
        this.loadingCities = false
      })
    },
    create() {
      this.region.title = this.region.name;
      this.region.code = this.region.kladr_id;
      // if (!this.region.code) return this.$root.notify('Необходимо выбрать город из списка', 'error')

      this.loading = true
      this.$store.state.server.request(`cities/createRegion/${this.city_id}`, this.region, () => {
        this.loading = false
        this.$root.notify('Регион добавлен', 'success')
        this.opened = false
        setTimeout(() => location.reload(), 500);
      }, (data) => {
        this.loading = false
        if (data && data.error) this.$root.notify(data.error, 'error')
      })
    }
  },
  watch: {
    searchCities(val) {
      val && this.findCity(val)
    },
  }
}
</script>

<style scoped>

</style>
