<template>
  <v-dialog data-app v-model="opened" @input="onToggle()">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>
      <v-card-title>{{ article.title }}</v-card-title>
      <v-card-text>
        <vue-editor v-model="article.text"></vue-editor>
      </v-card-text>
      <v-card-actions>
        <v-btn :loading="loading" @click="update">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Article",
  data() {
    return {
      opened: false,
      id: null,
      loading: false,
      article: {},
    }
  },
  methods: {
    open(article, city_id, type = 'article') {
      this.opened = true
      this.article = article;
      this.article.type = type;
      this.article.city_id = city_id;
    },
    onToggle() {
      this.article = {};
      this.loading = false;
    },
    update() {
      this.loading = true
      this.$store.state.server.request(`cities/updateArticle/${this.article.n_id}`, this.article, () => {
        this.loading = false
        this.$root.notify('Сохранено', 'success')
        this.opened = false
        // setTimeout(() => location.reload(), 500);
      }, (data) => {
        this.loading = false
        if (data && data.error) this.$root.notify(data.error, 'error')
      })
    }
  },
}
</script>

<style scoped>

</style>